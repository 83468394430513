function initSurvey() {
    let  logic_survey = {
        1:{
            yes: 2,
            no: 3
        },
        2:{
            yes: 5,
            no: 4
        },
        3:{
            yes: 10,
            no: 11
        },
        4:{
            yes: 6,
            no: 8
        },
        5:{
            yes: 15,
            no: 7
        },
        6:{
            yes: 9,
            no: 5
        },
        7:{
            yes: 15,
            no: 13
        },
        11:{
            yes: 12,
            no: 16
        },
        13:{
            yes: 14,
            no: 15
        },
        14:{
            yes: 15,
            no: 16
        }
    }


    $(".survey-button").on("click", function () {

        let question_id= $(this).data("question");
        let answer=$(this).data("answer");

        let question_block_id = '#question-' + question_id;
        let next_question_block_id = '#question-' + logic_survey[question_id][answer];

        $(question_block_id).toggleClass("is-hidden");
        $(next_question_block_id).toggleClass("is-hidden");

    });
}